import React, { useState, useEffect } from "react";
import Preloader from "./components/Preloader/Preloader";
import Subscribe from "./components/Subscribe/Subscribe";
import { SocialIcon } from 'react-social-icons';

// import logo from './logo.svg';
import './App.css';

var psl = require('psl');
var current_domain_parsed = psl.parse(window.location.hostname);
var current_domain = current_domain_parsed.domain;
//console.log(current_domain);

//https://stackoverflow.com/questions/40027487/dynamically-requiring-images-for-react-webpack-or-what-is-cost-of-many-requirei
const imageReq = require.context("./images", false, /\.jpg$/);

const IMG_LIST = [
  "cosmic-timetraveler-LhDWW8PhPoE-unsplash.jpg",
  "craig-cameron-BTqLREyDs2Q-unsplash.jpg",
  "ivana-cajina-dQejX2ucPBs-unsplash.jpg",
  "kunal-shinde--f0YLss50Bs-unsplash.jpg",
  "lukasz-szmigiel-jFCViYFYcus-unsplash.jpg",
  "sabeer-darr-Upz-tnx2v2s-unsplash.jpg",
  "sajid-ahmad-zQZ4L9WrCJ8-unsplash.jpg",
  "silvestri-matteo-6-C0VRsagUw-unsplash.jpg"
];

// https://stackoverflow.com/questions/30568796/how-to-store-configuration-file-and-read-it-using-react
const DOMAIN_CONFIG = [
    {domain: 'tals.ma', type: 'rent', params: ['email'], subscription: true, socials: false},
    {domain: 'dou.ma', type: 'rent', params: ['email'], subscription: true, socials: false},
    {domain: 'veehouderijtalsma.frl', type: 'rent', params: ['email', 'www'], subscription: true, socials: false},
    {domain: 'talsma.frl', type: 'rent', params: ['email', 'www', 'sub-only'], subscription: true, socials: false},
    {domain: 'eindelijksnelinternet.nl', type: 'rent', params: ['email', 'www'], subscription: true, socials: false},
    {domain: 'tijgeriszoek.nl', type: 'under_construction', params: [], subscription: true, socials: false},
    {domain: 'wytsetalsma.nl', type: 'under_construction', params: [], subscription: true, socials: true},
    {domain: 'veehouderijtalsma.nl', type: 'placeholder', params: [], subscription: false, socials: false},
    {domain: 'localhost', type: 'rent', params: ['email'], subscription: true, socials: false}
];

var DomIdx = DOMAIN_CONFIG.findIndex(entry => entry.domain === current_domain);
if (DomIdx === -1) {
  DomIdx = DOMAIN_CONFIG.findIndex(entry => entry.domain === 'localhost');
}
const CONFIG = DOMAIN_CONFIG[DomIdx];
// console.log(CONFIG);
document.title = CONFIG.domain;
//https://medium.com/developer-rants/what-if-height-100vh-is-bigger-than-your-screen-7f39c62ac170

// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array/18650169#18650169
function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

// https://jack72828383883.medium.com/how-to-preload-images-into-cache-in-react-js-ff1642708240

const BG_IMG = shuffle(IMG_LIST)[0];
const BG_IMG_URL = imageReq(`./${BG_IMG}`).default;
//console.log(BG_IMG_URL)

function App() {
  const [LoaderCompleted, setLoaderCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  function setLoaderCompletedCallback (completed)  { setLoaderCompleted(completed); }
  var HEADER = "";
  var BODYMSG = "";
  if(CONFIG.type === 'under_construction') {
    HEADER = "Under construction";
    if(CONFIG.subscription) {
      BODYMSG = "Our website is still in development. We will be here soon, subscribe to be notified!";
    } else {
      BODYMSG = "Our website is still in development. We will be here soon!";
    }
  } else if(CONFIG.type==='rent') {
    var email, www, subonly = false;
    var CONTACT_MSG = '';
    if(CONFIG.socials && CONFIG.subscription) {
      CONTACT_MSG = "Please contact me by email (see footer) or subscribe to discuss further details!"
    } else if (CONFIG.socials) {
      CONTACT_MSG = "Please contact me by email (see footer) to discuss further details!"
    } else if (CONFIG.subscription) {
      CONTACT_MSG = "Please subscribe to discuss further details!"
    }
    if (CONFIG.params.indexOf('email') >= 0) {
      email = true;
    }
    if (CONFIG.params.indexOf('www') >= 0) {
      www = true;
    }
    if (CONFIG.params.indexOf('sub-only') >= 0) {
      subonly = true;
    }
    if(subonly && (www || email)) {
      HEADER = "Subdomain addresses are for rent!";
    } else if(www) {
      HEADER = "This domain is for rent!";
    } else if(email) {
      HEADER = "Join us!";
    }
    if(www && email) {
      BODYMSG = "Both e-mail and webhosting can be offered!";
    } else if(www) {
      BODYMSG = "Webhosting can be offered!";
    } else if(email) {
      BODYMSG = "Do you want your very special e-mail address? You are in luck! E-mail services can be offered!";
    }
    BODYMSG += " "+CONTACT_MSG;
  }

  useEffect(() => {
    const cacheImages = async (srcArray) => {
      console.log("Prefetching....")
      const promises = await srcArray.map((src) => {
        return new Promise(function (resolve, reject) {
          const img = new Image();
          console.log(src);
          img.src = src;
          img.onload = resolve();
          img.onerror = reject();
        });
      });
      await Promise.all(promises);
      console.log("Done!")
      console.log(promises);
      setIsLoading(false);
    }
    cacheImages(['./static/media/'+BG_IMG]);
  }, []);
  useEffect(() => {
    if(!isLoading) {
      // https://stackoverflow.com/questions/60201147/i-want-to-apply-a-background-image-to-the-body-of-only-one-component
      document.body.style.backgroundImage = `url('${BG_IMG_URL}')`;
    }
  }, [isLoading]);
  //
  // {isLoading && <Spinner animation="grow" />}
  return (
    <div className="App">
      <Preloader setLoaderCompleted={setLoaderCompletedCallback} isLoading={isLoading} />
      {(HEADER || BODYMSG || CONFIG.subscription) && <div className="container"><div className="content">
        {HEADER && <h1>{HEADER}</h1>}
        {BODYMSG && <p>{BODYMSG}</p>}
        {CONFIG.subscription && <Subscribe config={CONFIG}/>}
      </div></div>}
      {CONFIG.socials &&
      <footer>
        <SocialIcon url="https://www.linkedin.com/in/wytse-talsma/" />
        <SocialIcon url="mailto://w.talsma@devta.nl" />
      </footer>
      }
    </div>
  );
}

export default App;
