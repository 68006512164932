import React, { useEffect } from "react";
import RingLoader from "react-spinners/RingLoader";
import "./Preloader.css";

// https://css-tricks.com/the-shapes-of-css/
// https://bashooka.com/coding/react-loading-spinner-components/

const CUSTOM_SPINNER = false;
const WAIT_INSTEAD_OF_DICTATE = true;

const Preloader = (props) => {

  useEffect(() => {
    function preloader(t) {
      let preload = document.querySelector(".preloader");
      setTimeout(() => {
        preload.style.opacity = "0";
        setTimeout(() => {
          preload.style.display = "none";
          props.setLoaderCompleted(true);
        }, 1000);
      }, t);
    }
    if(!WAIT_INSTEAD_OF_DICTATE) {
      preloader(3000);
    } else {
      preloader(0);
    }
  },[props]);

  return (
      <div className="preloader">
        <div className="spinner_wrap">
          {CUSTOM_SPINNER?<div className="spinner" />:<RingLoader />}
        </div>
      </div>
  );
}

export default Preloader;
